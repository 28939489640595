import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3761.2372725830965!2d-99.18476088468304!3d19.48842499394215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAzcapotzalco%2C%20CDMX%20San%20Marcos%208%20Cond.%201%20M%201!5e0!3m2!1ses!2smx!4v1625791144344!5m2!1ses!2smx"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
